@import url("./font.css");
@import url("./color.css");

a {
  text-decoration: none;
  color: inherit;
}

.button-large-primary {
  display: inline-block;
  direction: row;
  /* align-items: center; */
  justify-content: center;
  background-color: var(--brown-080);
  font-family: "Averia Serif Libre", serif;
  font-size: 20px;
  padding-left: 24px;
  padding-right: 24px;
  height: 60px;
  border-radius: 36px;
  color: white;
  box-shadow: none;
  border: none;
  transition: 0.2s ease;
  gap: 16px;
  align-self: flex-start
}

.button-large-primary:hover{
  cursor: pointer;
  background-color: var(--brown-070);
}


.button-large {
  display: inline-block;
  display: flex;
  direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--brown-030);
  font-family: "Averia Serif Libre", serif;
  font-size: 24px;
  padding-left: 24px;
  padding-right: 24px;
  height: 60px;
  border-radius: 36px;
  color: var(--primary-color);
  box-shadow: none;
  border: none;
  transition: 0.2s ease;
  gap: 16px;
}

.button-large:hover {
  box-shadow: var(--box-shadow-primary), var(--box-shadow-secondary);
  cursor: pointer;
}

.button-large:disabled {
  background-color: transparent;
  cursor: pointer;
  color: var(--brown-040)
}

.button-large:disabled:hover {
  box-shadow: none;
}


/*medium*/
.button-medium-primary {
  display: inline-block;
  color:white;
  display: flex;
  direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: var(--brown-080);
  font-family: "Averia Serif Libre", serif;
  font-size: 18px;
  border-radius: 24px;
  border: none;
  padding: 8px 20px 8px 20px;
  cursor: pointer;
}

.button-medium-primary:hover{
  background-color: var(--brown-070);
}

.button-medium-secondary {
  display: flex;
  direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: transparent;
  font-family: "Averia Serif Libre", serif;
  font-size: 18px;
  color: var(--primary-color);
  border: none;
  padding: 8px 12px 8px 12px;
  cursor: pointer;

}

.button-medium-secondary.selected {
  background-color: white;
  border-radius: 8px;
}



.button-medium-secondary:hover {
  background-color: var(--brown-030);
  color: var(--primary-color);
  border: none;
  border-radius: 8px;

}

.button-medium-subtle {
  display:inline-block;
  display: flex;
  direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-family: "Averia Serif Libre", serif;
  font-size: 18px;
  background-color: transparent;
  border: none;
  border-radius: 8px;
  padding: 8px 12px 8px 12px;
  height:40px


}
.button-medium-subtle:hover{
  background-color: var(--brown-020);
  cursor: pointer;

}

.button-medium-subtle.selected {
  background-color: white;
  border:none
}




.button-small-subtle {
  display:inline-block;
  font-family: "Averia Serif Libre", serif;
  font-size: 16px;
  background-color: transparent;
  border: none;
  border-radius: 8px;
  padding: 4px 8px 4px 8px;
  height:32px

}
.button-small-subtle:hover{
  background-color: var(--brown-020);
  cursor: pointer;


}

.button-small-subtle.selected {
  background-color: white;
  border:none
}


.back-button {

  background-color: var(--brown-030);
  font-family: "Averia Serif Libre", serif;
  font-size: 20px;
  width: 112px;
  height: 64px;
  border-radius: 16px;
  color: var(--primary-color);
  box-shadow: none;
  border: none;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: var(--primary-color);
  /* Darken primary button color on hover */
  color: white;
  cursor: pointer;
}

.button-iconOnly:disabled {
  background-color: var(--brown-010);

}

.button-iconOnly:hover {
  background-color: var(--primary-color);
  /* Darken primary button color on hover */
  color: white;
  cursor: pointer;
}
