@import url('https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

body,html{
  font-family: "Averia Serif Libre", serif ;
  font-weight: 400;
  font-style: normal;
  size:16px;
  text-align: left;
}

h1{
    font-family: "Averia Serif Libre", serif ;
    font-weight: 600;
    font-style: normal;
    size: 24px;
    margin:0
}

h2{
    font-family: "Averia Serif Libre", serif ;
    font-weight: 500;
    font-style: normal;
    size: 18px;
    margin: 0;
}

h3{
  font-family: "Averia Serif Libre", serif ;
  font-weight: 500;
  font-style: normal;
  size: 16px;
  margin:0;
}

h4{
  font-family: "Averia Serif Libre", serif ;
  font-weight: 500;
  font-style: normal;
  size: 14px;
  margin:0;
}

p{
  margin:0;
}