
:root {

    --primary-color: #4F3D2C; /* DarkBrown */
    --primary-color-hover:#6F5944;
    --brown-000:#FFFDF8;
    --brown-010:#F6F3EC;
    --brown-020:#EFE9DB;
    --brown-030:#DDD4C1;
    --brown-040:#C4B8A6;
    --brown-050:#9F917C;
    --brown-060:#8B7D70;
    --brown-070:#6C5541;
    --brown-080:#59432F;
    --brown-080:#503E2E;
    --text-primary: #222222; /* Gray */
    /* Other Colors */
    --text-secondary: #ffc107; /* Yellow */
    --success-color: #28a745; /* Green */
    --error-color: #dc3545; /* Red */
    /* Background Colors */
    --background-color: #f8f9fa; /* Light Gray */
    --card-background-color: #ffffff; /* White */
    --emotionTag-orange:#FFE9CF;
    --emotionTag-red:#FFEBEB;
    --emotionTag-green:#D7F4D3;
    --emotionTag-blue:#DCEBF2;
    --red-040:#E37373;
    --green-040:#83CC77;
    --blue-040:#84BDD5;
    --orange-040:#DD8A28;

  }