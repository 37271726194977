@import url("./font.css");
@import url("./color.css");
@import url('./Button.css');
@import url('./shadow.css');

:root {
  --box-shadow-primary: 0px 0px 2px var(--brown-040);
  --box-shadow-secondary: 0px 0px 12px var(--brown-030);
}


html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--brown-010);
  transition: all 0.3s;
  font-family: "Averia Serif Libre", serif !important;
}

/*this is the global padding, intended to use for responsive */
.App {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  font-family: "Averia Serif Libre", serif;

}

/*this is a container that specifeis the middle align behavior */
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  gap: 16px;
}

/*navigation bar */
.navBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;
  justify-content: center;
  height: 56px;


}

.aquarium {
  position: relative;
  width: 100%;
  max-width: 644px;
  height: 200px;
  background-size: cover;
  background-position: center;
  border-radius: 16px;
  background-color: var(--brown-030);
  border: none;
}

/* record section */
.journalBox {
  width: 100%;
  max-width: 644px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.maxWidthSpacer {
  width: 100%;
  max-width: 644px;
}

.textArea {
  display: block;
  background-color: var(--brown-000);
  border-radius: 16px;
  height: 12em;
  border: none;
  resize: none;
  font-size: 20px;
  font-family: "Averia Serif Libre", serif;
  /* width: 100%; */
  caret-color: var(--primary-color);
  outline: none;
  text-align: center;
  transition: opacity 0.5s ease-in-out;
  padding: 16px
}

.recordFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
  width: 100%;

}

.tag {
  font-size: 14px;
  padding: 4px 8px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
}


.moveSteps {
  margin-top: 16px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* Aligns items to each end of the container */
}



.distortedGroup {

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 16px;
  flex-wrap: wrap;
}


.optionBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  height: 120px;
  background-color: var(--brown-020);
  border-radius: 8px;
  font-family: "Averia Serif Libre", serif;
  font-size: 18px;
  font-weight: 500;
  padding: 16px;
  cursor: pointer;
  transition: 0.3s;
  text-align: left;
  border: 1px solid var(--brown-030);
  flex: 1;
  min-width: 200px;


}

.optionBox:hover {

  background-color: var(--brown-030);


}

.optionBox.selected {
  background-color: var(--primary-color);
  color: white;
}

.optionBox.selected:hover {
  background-color: var(--brown-080);
}

.optionBoxText {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.footer {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 64px;
}

.prevButton {
  position: absolute;
  left: 0;
}

.nextButton {
  position: absolute;
  right: 0;
}



@keyframes shimmer {
  0% {
    background-position: -40rem 0;
  }

  100% {
    background-position: 40rem 0;
  }
}

.shimmer {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, var(--brown-020), var(--brown-030), var(--brown-020) 33%);
  background-size: 80rem 100%;
}

.summaryBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--brown-020);
  border-radius: 8px;
  font-family: "Averia Serif Libre", serif;
  padding: 16px;
  cursor: pointer;
  transition: 0.3s;
  text-align: left;
  border: 1px solid var(--brown-030);

}

.summaryImg {
  width: 100%;
  border-radius: 8px;
}

.toggle-container {
  display: inline-flex;
  gap: 16px;
  /* Adds 8px gap between each child element */
  padding: 8px;
  background-color: var(--brown-020);
  border-radius: 8px;
  justify-content: center;
  /* Centers items horizontally */
  align-items: center;
  /* Aligns items vertically */

}


.resultCard {
  max-width: 600px;
  background-color: #FFFDF8;
  display: flex;
  flex-direction: row;
  gap: 64px;
  padding: 16px 24px;
  border-radius: 24px;
  justify-content: flex-start;

}

.resultTitle {
  width: 100px;
  padding: 16px 0;
  color: var(--brown-080);
}

@media (max-width: 768px) {
  .resultCard {
    gap: 0px;
    padding: 16px 24px;
    flex-direction: column;
  }

  .resultTitle {
    width: 100px;
    padding: 0px 0px 12px 0px;
    color: var(--brown-080);
  }


}




@keyframes shimmer {
  0% {
    background-position: -80rem 0;
  }

  100% {
    background-position: 80rem 0;
  }
}

.shimmer {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, var(--brown-020), var(--brown-030), var(--brown-020) 33%);
  background-size: 80rem 100%;
  width: 100%;
}

.actionBox {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 28px;
  background-color: var(--brown-010);
  border-radius: 16px;
  text-align: left;
  color: var(--brown-080);
  height: 80px;
  border: 1px solid var(--brown-030);
  max-width: 240px;

}

.actionTop {
  display: flex;
  flex-direction: column;
  /* gap: 12px; */
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
}



.tabs-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.signout-container {
  position: absolute;
  right: 0
}

@keyframes shimmer {
  0% {
    background-position: -40rem 0;
  }

  100% {
    background-position: 40rem 0;
  }
}

.shimmer {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, var(--brown-020), var(--brown-030), var(--brown-020) 33%);
  background-size: 80rem 100%;
  border-radius: 8px;
}


.signIn {
  background-color: #000;
  width: 600px;
}


.container.landing {
  max-width: 760px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.heading {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 200px;
}

.content {
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: center;
}

.text-content {
  display: flex;
  flex-direction: column;
  max-width: 420px;
  gap: 24px
}

.image-content img {
  width: 100%;
  max-width: 400px;
  align-content: center;
}

@media (max-width: 768px) {
  .heading {
    margin-bottom: 80px;
  }

  .content {
    flex-direction: column;
    align-items: center;
  }

  .text-content {
    max-width: 100%;
  }

  .image-content {
    order: -1;
    /* Places the image on top */
  }

  .image-content img {
    width: 100%;
    max-width: 80%;
  }
}

.popup {
  display: flex;
  flex-direction: column;
  background-color: var(--brown-000);
  padding: 24px;
  border-radius: 16px;
  gap:16px
}

.popup-header{
  display:flex;
  justify-content: space-between;
  gap:16px
}

.menuOverlayContainer {
  position: relative;
  display: inline-block;

}



.settings-dropdown {
  display: block;
  position: absolute;
  border-radius: 4px;
  top: 8px;
  right: 0;
  z-index: 1;
  background-color: white;
  min-width: 120px;
  align-items:flex-start;
  box-shadow: var(--elevation-01)
}
.settings-dropdown:hover{
  cursor:pointer;

}



.menu-item-small{
  padding:8px 12px
}

.menu-item-small:hover{
  background-color: var(--brown-010);
  cursor:pointer
}

.removeClerkStyling{
  background-color: transparent;
  border:none;
  font-size: 16px;
  font-family: "Averia Serif Libre", serif;
}